








































































































































































































































import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { AxiosError } from "axios";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import BtnCreateSubtype from "../buttons/BtnCreateSubtype.vue";
import { Subtypes } from "@/models/Subtypes";
export default Vue.extend({
  components: { BtnCreateSubtype },
  name: "nomenclators",
  data() {
    return {
      search: "",
      menus: false,
      filter: "",
      DialogEdit: false,
      dialogConfirmRemove: false,
      itemSelect: {},
      loading: false,
      seeCancels: false,
      minprice: 0,
      maxprice: 0,
      price: 0,
      proceduresTypes: [
        { label: "Show all", value: "all" },
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ],
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "", value: "actions", sortable: false },
      ],
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["nomenclators"]),
    ...mapGetters(["getShowPrice"]),
    PriceValid() {
      if (this.minprice == 0 && this.maxprice == 0 && this.price == 0) {
        return true;
      }
      if (this.minprice != 0 || this.maxprice != 0) {
        if (
          Number(this.minprice) > Number(this.maxprice) &&
          Number(this.price) <= Number(this.minprice) &&
          Number(this.price) >= Number(this.maxprice)
        ) {
          return true;
        } else if (
          Number(this.minprice) < Number(this.maxprice) &&
          Number(this.price) >= Number(this.minprice) &&
          Number(this.price) <= Number(this.maxprice)
        ) {
          return true;
        }
        if (this.minprice == this.maxprice && this.price == this.minprice) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    if (this.getShowPrice) {
      this.headers = [
        { text: "Name", align: "start", value: "name" },
        { text: "Min Price", value: "minValue", sortable: true },
        { text: "Default Price", value: "price", sortable: true },
        { text: "Max Price", value: "maxValue", sortable: true },
        { text: "", value: "actions", sortable: false },
      ];
    }
    this.loading = true;
    await this.actListProcedireSubtypes();
    this.loading = false;
    this.filter = this.proceduresTypes[0].label;
  },
  methods: {
    ...mapActions("crmSettingsModule", [
      "actListProcedireSubtypes",
      "actUpdateNomenclator",
      "actListNomCanceles",
      "actRemoveNomenclator",
    ]),
    toRemove(item: any) {
      this.dialogConfirmRemove = true;
      this.itemSelect = item;
    },
    toEdit(item: any) {
      this.DialogEdit = true;
      this.itemSelect = item;
    },
    cancel() {
      this.DialogEdit = false;
      this.dialogConfirmRemove = false;
      this.itemSelect = {};
    },
    remove() {
      this.loading = true;
      this.actRemoveNomenclator({
        id: (this as any).itemSelect.id,
        type: (this as any).itemSelect.type,
      })
        .then(() => {
          this.dialogConfirmRemove = false;
          this.loading = false;
          notifyInfo(this.$t("notifyRemove"));
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    showEditDialog(item: Subtypes) {
      this.DialogEdit = true;
      this.minprice = item.minValue ? item.minValue : 0;
      this.maxprice = item.maxValue ? item.maxValue : 0;
      this.price = item.price ? item.price : 0;
      this.itemSelect = item;
    },
    update(): void {
      let body = {};

      if (this.getShowPrice) {
        this.minprice = Number(this.minprice);
        this.maxprice = Number(this.maxprice);
        this.price = Number(this.price);
        if (this.minprice > this.maxprice) {
          const temp = this.minprice;
          this.minprice = this.maxprice;
          this.maxprice = temp;
        }

        if (this.minprice != 0 && this.maxprice != 0) {
          body = {
            id: (this as any).itemSelect.id,
            name: (this as any).itemSelect.name,
            type: (this as any).itemSelect.type,
            minValue: this.minprice,
            maxValue: this.maxprice,
            price: this.price,
          };
        } else {
          if (this.maxprice != 0) {
            body = {
              id: (this as any).itemSelect.id,
              name: (this as any).itemSelect.name,
              type: (this as any).itemSelect.type,
              maxValue: this.maxprice,
              price: this.price,
            };
          } else {
            body = {
              id: (this as any).itemSelect.id,
              name: (this as any).itemSelect.name,
              type: (this as any).itemSelect.type,
            };
          }
        }
      } else {
        body = {
          id: (this as any).itemSelect.id,
          name: (this as any).itemSelect.name,
          type: (this as any).itemSelect.type,
        };
      }

      this.actUpdateNomenclator(body)
        .then(() => {
          this.actListProcedireSubtypes((this as any).itemSelect.type);
          this.filter = (this as any).itemSelect.type;
          this.DialogEdit = false;
          notifySuccess("The Subtype has been updated");
        })
        .catch((error: AxiosError) => {
          this.loading = false;
          this.DialogEdit = false;
          notifyError(
            error.response?.data.message || error.response?.data.details
          );
        });
    },
    createdSub(typecreated: any) {
      this.filter = typecreated;
      this.filterSubtypes(typecreated);
    },
    async filterSubtypes(type: string) {
      this.loading = true;
      if (this.seeCancels) {
        if (type == "all" || type == "Show all") {
          await this.actListNomCanceles();
          this.loading = false;
        } else {
          await this.actListNomCanceles(type);
          this.loading = false;
        }
      } else {
        if (type == "all" || type == "Show all") {
          await this.actListProcedireSubtypes();
          this.loading = false;
        } else {
          await this.actListProcedireSubtypes(type);
          this.loading = false;
        }
      }
    },
  },
});
